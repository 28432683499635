import React, { forwardRef } from "react";

import {
  ButtonCTA as ButtonCTAStyled,
  ButtonCTAWhite as ButtonCTAWhiteStyled,
  ButtonCTADarkest as ButtonCTADarkestStyled,
  ButtonCTADarkestOutline as ButtonCTADarkestOutlineStyled,
  Area as AreaStyled,
} from "./style";

export default function ButtonCTA({ children, ...rest }) {
  return <ButtonCTAStyled {...rest}>{children}</ButtonCTAStyled>;
}

export function ButtonCTAWhite({ children, ...rest }) {
  return <ButtonCTAWhiteStyled {...rest}>{children}</ButtonCTAWhiteStyled>;
}

export function ButtonCTADarkest({ children, ...rest }) {
  return <ButtonCTADarkestStyled {...rest}>{children}</ButtonCTADarkestStyled>;
}

export function ButtonCTADarkestOutline({ children, ...rest }) {
  return <ButtonCTADarkestOutlineStyled {...rest}>{children}</ButtonCTADarkestOutlineStyled>;
}

export const Area = forwardRef((props, ref) => (
  <AreaStyled ref={ref} {...props}>
    {props.children}
  </AreaStyled>
));
