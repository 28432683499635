import { Link } from "gatsby";
import tw from "tailwind-styled-components";

export const Area = tw.div`
  w-full 
  pt-2
  py-14
  flex
  justify-center
  items-center
  content-center
`;

export const ButtonCTA = tw.a`
  outline-none
  no-underline 
  text-blue-pensamental-dark 
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  bg-blue-pensamental-light 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:bg-blue-pensamental-light
  hover:scale-110
  hover:text-white
  focus:ring-2 focus:ring-blue-pensamental-dark
`;

export const ButtonCTAWhite = tw.a`
  outline-none
  no-underline 
  text-blue-pensamental-darkest
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  bg-white 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:scale-110
  focus:ring-2 focus:ring-blue-pensamental-light
`;

export const ButtonCTADarkest = tw(Link)`
  outline-none
  no-underline 
  text-white
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  bg-blue-pensamental-darkest 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:scale-110
  focus:ring-2 focus:ring-blue-pensamental-dark
`;

export const ButtonCTADarkestOutline = tw.a`
  outline-1
  no-underline 
  text-blue-pensamental-darkest
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  border-2
  border-blue-pensamental-darkest 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:scale-110
  focus:ring-2 focus:ring-blue-pensamental-dark
`;
