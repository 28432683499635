import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";
import Tracker from "@openreplay/tracker/cjs";

// images
import BenefitIcon01 from "../../images/benefit-icon-01.svg";
import BenefitIcon02 from "../../images/benefit-icon-02.svg";
import BenefitIcon03 from "../../images/benefit-icon-03.svg";
import FormArticleBg from "../../images/form-article-bg.webp";
import AuthorityImg from "../../images/form-bernardo-hero.webp";

// components
import Main from "../../components/main";
import Header from "../../components/header";
import Seo from "../../components/seo";
import Footer from "../../components/footer";
import { ButtonCTADarkest } from "../../components/buttonCTA";

// styles
import * as SC from "../../styles/form";

// variables
const IS_EMPTY_STANDARD = /^$/g;

const IS_EMAIL_STANDARD = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const IS_PHONE_STANDARD = /(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}/g;

const SLACK_POST_URL = "https://hooks.slack.com/services/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";

const FORM_FIELDS = { NOME: "entryName", EMAIL: "entryEmail", WHATSAPP: "entryWhatsapp", SALARIO: "entrySalary", OBSTACULO: "entryBarrier" };

const FORM_FIELDS_ALL = [FORM_FIELDS.NOME, FORM_FIELDS.EMAIL, FORM_FIELDS.WHATSAPP, FORM_FIELDS.SALARIO, FORM_FIELDS.OBSTACULO];

const EMPTY_ERROR_ITEM = { error: false, message: "" };

const SALARY_OPTIONS = [
  {
    label: "Menos de R$2.000,00",
    value: "Menos de R$2.000,00",
  },
  {
    label: "Entre R$2.000,00 e R$5.000,00",
    value: "Entre R$2.000,00 e R$5.000,00",
  },
  {
    label: "Entre R$5.000,00 e R$10.000,00",
    value: "Entre R$5.000,00 e R$10.000,00",
  },
  {
    label: "Entre R$10.000,00 e R$50.000,00",
    value: "Entre R$10.000,00 e R$50.000,00",
  },
  {
    label: "Mais de R$50.000,00",
    value: "Mais de R$50.000,00",
  },
];

// helpers
const getGoogleFormAnswerPost = (formValues) => {
  const GOOGLE_FORM_URL = `https://docs.google.com/forms/d/e/1FAIpQLSerT-KYHiOAMqvUdpR9TG0YNGMyO_PqUcLFiu7KByVMW2lbjw/formResponse?usp=pp_url&entry.1803536768=${
    formValues[FORM_FIELDS.NOME]
  }&entry.193989842=${formValues[FORM_FIELDS.EMAIL]}&entry.961105102=${formValues[FORM_FIELDS.WHATSAPP]}&entry.1072928330=${formValues[FORM_FIELDS.SALARIO].replace(/\s/g, "+")}&entry.1621772300=${
    formValues[FORM_FIELDS.OBSTACULO]
  }&submit=Submit`;

  return fetch(GOOGLE_FORM_URL, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

const getSlackMessage = (formValues) => `
    Acabamos de receber uma nova resposta em nosso formulário da Mentoria:
    - Nome: ${formValues[FORM_FIELDS.NOME]}
    - Email: ${formValues[FORM_FIELDS.EMAIL]}
    - WhatsApp: ${formValues[FORM_FIELDS.WHATSAPP]}
    - Salário: ${formValues[FORM_FIELDS.SALARIO]}
    - Obstáculo: ${formValues[FORM_FIELDS.OBSTACULO]}
  `;

const postSlackMessage = async (formValues) =>
  await axios.post(SLACK_POST_URL, JSON.stringify({ text: getSlackMessage(formValues) }), {
    withCredentials: false,
    transformRequest: [
      (data, headers) => {
        delete headers.post["Content-Type"];
        return data;
      },
    ],
  });

const formItemHasError = (name, value) => {
  const IS_MORE_THAN_255 = value?.length > 255;
  const IS_FIELD_ANY = FORM_FIELDS_ALL.includes(name);
  const IS_FIELD_EMAIL = [FORM_FIELDS.EMAIL].includes(name);
  const IS_FIELD_WHATSAPP = [FORM_FIELDS.WHATSAPP].includes(name);

  if (IS_FIELD_EMAIL) {
    if (!new RegExp(IS_EMAIL_STANDARD).test(value)) {
      return true;
    }

    return false;
  }

  if (IS_FIELD_WHATSAPP) {
    if (!new RegExp(IS_PHONE_STANDARD).test(value)) {
      return true;
    }

    return false;
  }

  if (IS_FIELD_ANY) {
    if (new RegExp(IS_EMPTY_STANDARD).test(value)) {
      return true;
    }

    if (IS_MORE_THAN_255) {
      return true;
    }

    return false;
  }
};

const getExpirationParam = () => {
  const hoursToAdd = 24;
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + hoursToAdd * 60 * 60 * 1000);
  const param = { expiration: futureDate };
  const encodedParam = btoa(JSON.stringify(param));
  return `?t=${encodedParam}`;
};

// components
function InputAndLabel({ onChange, value, htmlFor, inputType, label, placeholder, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <SC.Label htmlFor={htmlFor}>{label}</SC.Label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <SC.Input $hasError={hasError} onChange={onChange} value={value} type={inputType} name={htmlFor} id={htmlFor} placeholder={placeholder} />
      </div>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

function SelectAndLabel({ onChange, value, htmlFor, label, options, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <SC.Label htmlFor={htmlFor}>{label}</SC.Label>

      <SC.Select $hasError={hasError} onChange={onChange} value={value} name={htmlFor} id={htmlFor}>
        {options.map((option) => (
          <option value={option.value} key={option.label}>
            {option.label}
          </option>
        ))}
      </SC.Select>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

function TextAreaAndLabel({ onChange, value, rows = 4, htmlFor, inputType, label, placeholder, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <SC.Label htmlFor={htmlFor}>{label}</SC.Label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <SC.TextArea $hasError={hasError} onChange={onChange} value={value} type={inputType} name={htmlFor} id={htmlFor} placeholder={placeholder} rows={rows} />
      </div>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

function InputWithMaskAndLabel({ onChange, value, mask, htmlFor, inputType, label, placeholder, hasError, errorMessage }) {
  return (
    <div className="text-left mb-4">
      <SC.Label htmlFor={htmlFor}>{label}</SC.Label>

      <div className="mt-1 relative rounded-md shadow-sm">
        <SC.InputWithMask $hasError={hasError} mask={mask} onChange={onChange} value={value} type={inputType} name={htmlFor} id={htmlFor} placeholder={placeholder} />
      </div>

      {hasError && <p className="mt-2 text-red-500 text-xs italic">{errorMessage}</p>}
    </div>
  );
}

// open replay
const tracker = new Tracker({ projectKey: "mJk2kHRhU2x0UP887beO", __DISABLE_SECURE_MODE: true });

// default
function FormComponent() {
  const isSSR = typeof window === "undefined";
  const [submitFailed, setSubmitFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formHasError, setFormHasError] = useState(true);
  const [formValues, setFormValues] = useState({
    [FORM_FIELDS.NOME]: "",
    [FORM_FIELDS.EMAIL]: "",
    [FORM_FIELDS.WHATSAPP]: "",
    [FORM_FIELDS.SALARIO]: SALARY_OPTIONS[0].value,
    [FORM_FIELDS.OBSTACULO]: "",
  });
  const [formErrors, setFormErrors] = useState({
    [FORM_FIELDS.NOME]: EMPTY_ERROR_ITEM,
    [FORM_FIELDS.EMAIL]: EMPTY_ERROR_ITEM,
    [FORM_FIELDS.WHATSAPP]: EMPTY_ERROR_ITEM,
    [FORM_FIELDS.SALARIO]: EMPTY_ERROR_ITEM,
    [FORM_FIELDS.OBSTACULO]: EMPTY_ERROR_ITEM,
  });

  const validateFormValue = (name, value) => {
    const IS_MORE_THAN_255 = value?.length > 255;
    const IS_FIELD_ANY = FORM_FIELDS_ALL.includes(name);
    const IS_FIELD_EMAIL = [FORM_FIELDS.EMAIL].includes(name);
    const IS_FIELD_WHATSAPP = [FORM_FIELDS.WHATSAPP].includes(name);

    if (IS_FIELD_EMAIL) {
      if (!new RegExp(IS_EMAIL_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite um e-mail válido." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }

    if (IS_FIELD_WHATSAPP) {
      if (!new RegExp(IS_PHONE_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite um número válido." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }

    if (IS_FIELD_ANY) {
      if (new RegExp(IS_EMPTY_STANDARD).test(value)) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite algum valor." } });
        return;
      }

      if (IS_MORE_THAN_255) {
        setFormErrors({ ...formErrors, [name]: { error: true, message: "Digite no máximo 255 caracteres." } });
        return;
      }

      setFormErrors({ ...formErrors, [name]: { error: false, message: "" } });
    }
  };

  const setFormProperties = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
    validateFormValue(name, value);
  };

  const handleSubmitForm = () => {
    setIsSubmitting(true);

    if (formHasError) {
      setIsSubmitting(false);
      return;
    }

    Promise.all([getGoogleFormAnswerPost(formValues)])
      .then(async (res) => {
        const slackResponse = await postSlackMessage(formValues);
        if (slackResponse.status === 200) window.location.replace(`/form/confirmacao${getExpirationParam()}`);
        else throw Error();
      })
      .catch(() => setSubmitFailed(true))
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    setFormHasError(
      Object.entries(formValues)
        .filter((formValue) => FORM_FIELDS_ALL.includes(formValue[0]))
        .map((formValue) => formItemHasError(formValue[0], formValue[1]))
        .some((error) => error),
    );
  }, [formValues]);

  useEffect(() => {
    if (!isSSR) {
      tracker.start();
    }
  }, []);

  return (
    <>
      <Seo title="| Formulário Mentoria" />
      <Main className="z-30">
        <Header blueDefault />

        <SC.HeaderSpacer />

        <img src={FormArticleBg} alt="Pensamental - Fundo BG" style={{ display: "none" }} />

        <SC.FormArticle>
          <SC.ContentInfo>
            <SC.SubInfo>Preencha o formulário que vamos ajudar você!</SC.SubInfo>

            <SC.LabelWrapper>
              <p className="max-w-screen-sm font-comfortaa text-blue-pensamental-light text-sm md:text-base">
                Assim que finalizar o formulário, você ganhará um <br className="hidden md:inline-block" /> bônus que fará toda a diferença.
              </p>
            </SC.LabelWrapper>

            <InputAndLabel
              onChange={(e) => setFormProperties(FORM_FIELDS.NOME, e.target.value)}
              value={formValues[FORM_FIELDS.NOME]}
              hasError={formErrors[FORM_FIELDS.NOME].error}
              errorMessage={formErrors[FORM_FIELDS.NOME].message}
              inputType="text"
              htmlFor={FORM_FIELDS.NOME}
              placeholder="Ex: John Doe"
              label="Nome Completo"
            />
            <InputAndLabel
              onChange={(e) => setFormProperties(FORM_FIELDS.EMAIL, e.target.value)}
              value={formValues[FORM_FIELDS.EMAIL]}
              hasError={formErrors[FORM_FIELDS.EMAIL].error}
              errorMessage={formErrors[FORM_FIELDS.EMAIL].message}
              inputType="email"
              htmlFor={FORM_FIELDS.EMAIL}
              placeholder="Ex: johndoe@email.com"
              label="E-mail"
            />
            <InputWithMaskAndLabel
              onChange={(e) => setFormProperties(FORM_FIELDS.WHATSAPP, e.target.value)}
              value={formValues[FORM_FIELDS.WHATSAPP]}
              hasError={formErrors[FORM_FIELDS.WHATSAPP].error}
              errorMessage={formErrors[FORM_FIELDS.WHATSAPP].message}
              inputType="tel"
              htmlFor={FORM_FIELDS.WHATSAPP}
              mask="(99) 99999-9999"
              placeholder="Ex: (51) 99707-9543"
              label="WhatsApp"
            />
            {false && (
              <SelectAndLabel
                onChange={(e) => setFormProperties(FORM_FIELDS.SALARIO, e.target.value)}
                value={formValues[FORM_FIELDS.SALARIO]}
                htmlFor={FORM_FIELDS.SALARIO}
                label="Qual é a sua receita mensal?"
                options={SALARY_OPTIONS}
              />
            )}
            <TextAreaAndLabel
              onChange={(e) => setFormProperties(FORM_FIELDS.OBSTACULO, e.target.value)}
              value={formValues[FORM_FIELDS.OBSTACULO]}
              hasError={formErrors[FORM_FIELDS.OBSTACULO].error}
              errorMessage={formErrors[FORM_FIELDS.OBSTACULO].message}
              inputType="text"
              htmlFor={FORM_FIELDS.OBSTACULO}
              placeholder="Ex: Tenho dificuldades em..."
              label="O que mais te prejudica hoje em dia?"
              rows={1}
            />
          </SC.ContentInfo>

          <SC.ContentInfoCTA>
            <SC.SubmitForm $disabled={formHasError} className="w-2/5 sm:w-1/5" onClick={handleSubmitForm}>
              {isSubmitting ? "CARREGANDO..." : "ENVIAR"}
            </SC.SubmitForm>
          </SC.ContentInfoCTA>

          {submitFailed && (
            <>
              <SC.ContentInfo>
                <SC.SubInfo>Hmmm, que estranho...</SC.SubInfo>
                <SC.Label>Identificamos um erro na submissão do formulário</SC.Label>
                <StaticImage src="../../images/fail.png" alt="Error" placeholder="blurred" imgStyle={{ objectFit: "contain" }} height={250} />
              </SC.ContentInfo>

              <SC.ContentInfoCTA $centered>
                <ButtonCTADarkest onClick={() => setSubmitFailed(false)} className="w-full sm:w-2/5 text-center">
                  COMEÇAR NOVAMENTE
                </ButtonCTADarkest>
              </SC.ContentInfoCTA>
            </>
          )}
        </SC.FormArticle>

        <article className="w-full flex flex-col text-center items-center bg-blue-pensamental-light p-10 ">
          <h2 className="max-w-screen-xl font-baloo text-4xl text-white text-left text-center mb-0 md:mb-6 leading-7">O que você irá aprender com o bônus:</h2>

          <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center border-b lg:border-b-0 border-r-0 lg:border-r">
                <SC.BenefitListItemIcon width="150" height="150" alt="Controlar a mente" src={BenefitIcon02} />
                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Controlar a mente</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Te ensinamos a controlar melhor suas emoções, pensamentos e comportamentos. Isso te torna capaz de solucionar a dificuldade que está vivendo sem precisar esperar anos pela sua
                    resposta.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>

            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center border-b lg:border-b-0 border-r-0 lg:border-r">
                <SC.BenefitListItemIcon width="150" height="150" alt="Aumentar a autoconfiança" src={BenefitIcon01} />

                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Aumentar a autoconfiança</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Quando nos deparamos com situações difíceis e não conseguimos viver como gostaríamos, a nossa autoconfiança vai lá pra baixo, não é mesmo? Nós vamos te ajudar para que voce mesma
                    consiga se reerguer novamente.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>

            <li className="block py-0 md:py-0">
              <div className="pl-0 pr-2 py-4 md:py-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center">
                <SC.BenefitListItemIcon width="150" height="150" alt="Reconstruir a identidade verdadeira" src={BenefitIcon03} />
                <SC.BenefitListItemInfo>
                  <h3 className="font-baloo text-xl text-white xs:text-center sm:text-center md:text-left lg:text-left">Reconstruir a identidade verdadeira</h3>
                  <span className="mt-2 font-comfortaa text-sm text-white xs:text-center sm:text-center md:text-left lg:text-left">
                    Muitos problemas da vida quebram a sua identidade e fazem você agir contra a sua vontade. Junto de nós, você irá se reconstruir de forma assertiva e autêntica para ser e viver como
                    você sempre quis.
                  </span>
                </SC.BenefitListItemInfo>
              </div>
            </li>
          </ul>
        </article>

        <SC.AuthorityArticle>
          <div className="flex flex-col xl:flex-row items-center justify-center max-w-full xl:max-w-screen-xl">
            <div className="relative w-full sm:w-3/5 md:w-2/5">
              <div style={{ top: "-20px", left: "-20px" }} className="absolute h-full rounded-md border-5 border-blue-pensamental-light w-full transform scale-90 z-5" />
              <img
                className="rounded-md border-5 border-blue-pensamental-darkest bg-center bg-cover bg-repeat transform scale-90 z-0"
                loading="lazy"
                alt="Construa uma vida que vale a pena viver"
                width="600"
                src={AuthorityImg}
              />
            </div>

            <div className="flex flex-col p-6 w-full md:w-3/5 text-center md:text-left">
              <p className="w-full py-2 font-comfortaa text-md text-gray-500">
                Bernardo Tietze é mentor de Saúde Mental e Desenvolvimento Humano, já tendo vencido a depressão, a ansiedade social, a baixa autoestima, a baixa autoconfiança e inúmeros desafios
                emocionais ao longo de sua trajetória tanto pessoal quanto profissional. Palestrante em instituições de ensino e empresas com foco no crescimento humano e inteligência emocional,
                atuando na promoção de saúde mental e posicionamento comunicativo para pessoas que buscam alta performance.
              </p>

              <p className="w-full font-comfortaa font-black text-md text-blue-pensamental-dark">#COMUNIDADEPENSAMENTAL</p>
            </div>
          </div>
        </SC.AuthorityArticle>

        <Footer hideFooter isDarkest />
      </Main>
    </>
  );
}

export default FormComponent;
