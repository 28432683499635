import React from "react";
import tw from "tailwind-styled-components";

import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import styled from "styled-components";

const PatternBG = styled.article`
  background-image: url("/static/form-article-bg-0b04054470bc68327e04215c060d357d.webp");
`;

export const HeaderSpacer = styled.div`
  width: 100%;
  height: 75px;
  background-color: transparent;
`;

// form

export const Form = tw.form``;

export const ContentInfo = tw.div`
  text-center
  w-full
  max-w-screen-md
  flex
  flex-col
`;

const ContentInfoCTAInner = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ContentInfoCTA = tw(ContentInfoCTAInner)`
  text-center
  w-full
  max-w-screen-md
  mt-2
`;

const SubInfoInner = styled.h2`
  line-height: 35px;
`;

export const SubInfo = tw(SubInfoInner)`
  mt-2 
  font-baloo
  text-4xl
  text-blue-pensamental-dark
  mb-4
  text-center
`;

export const FormArticle = tw(PatternBG)`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  p-6

  bg-cover
  bg-repeat
  bg-white
`;

export const LabelWrapper = tw.div`
  w-full
  text-xl
  mb-4
  flex
  justify-center
  items-center
  text-gray-400
`;

const SubSubInfoInner = styled.label`
  line-height: 25px;
`;

export const SubSubInfo = tw(SubSubInfoInner)`
  max-w-screen-sm
  font-comfortaa
  xs:text-sm sm:text-sm md:text-base lg:text-base text-base
  text-gray-400
`;

export const Label = tw.label`
  max-w-screen-sm
  font-comfortaa
  text-sm md:text-md
  text-gray-700
`;

export const TextArea = tw.textarea`
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25

${(props) =>
  props.$hasError
    ? `
  border-red-500
  focus:ring-red-700
  focus:border-red-700
`
    : `
  border-blue-pensamental-darkest
  focus:ring-blue-pensamental-light 
  focus:border-blue-pensamental-light 
`}
`;

export const Input = tw.input`
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25
  
  ${(props) =>
    props.$hasError
      ? `
    border-red-500
    focus:ring-red-700
    focus:border-red-700
  `
      : `
    border-blue-pensamental-darkest
    focus:ring-blue-pensamental-light 
    focus:border-blue-pensamental-light 
  `}
`;

export const Select = tw.select`
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25

  ${(props) =>
    props.$hasError
      ? `
    border-red-500
    focus:ring-red-700
    focus:border-red-700
  `
      : `
    border-blue-pensamental-darkest
    focus:ring-blue-pensamental-light 
    focus:border-blue-pensamental-light 
  `}
`;

export const InputWithMask = tw(InputMask)`
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500 placeholder-opacity-25

${(props) =>
  props.$hasError
    ? `
  border-red-500
  focus:ring-red-700
  focus:border-red-700
  `
    : `
    border-blue-pensamental-darkest
    focus:ring-blue-pensamental-light 
    focus:border-blue-pensamental-light 
`}
`;

export const InputCurrencyWithMask = tw(IntlCurrencyInput)`
  block
  w-full
  pl-4
  pr-4
  text-xs
  rounded-md
  placeholder-gray-500
  placeholder-opacity-25

  focus:text-gray-900
  active:text-gray-900
  active:placeholder-gray-900
  active:placeholder-opacity-100

  ${(props) => (props.$isEmpty ? "text-gray-200" : null)} 

  ${(props) =>
    props.$hasError
      ? `
    border-red-500
    focus:ring-red-700
    focus:border-red-700
  `
      : `
    border-blue-pensamental-darkest
    focus:ring-blue-pensamental-light 
    focus:border-blue-pensamental-light 
  `}
`;

export const SubmitForm = tw.button`
  outline-none
  no-underline 
  inline-flex 
  items-center 
  justify-center 
  px-12
  py-3
  font-baloo 
  transition
  duration-300
  ease-in-out 
  transform 
  hover:scale-110
  focus:ring-2 
  focus:ring-blue-pensamental-dark
  w-2/5 sm:w-1/5
  rounded-md
  ${(props) => (props.$disabled ? "bg-gray-300" : "bg-blue-pensamental-darkest")}
  ${(props) => (props.$disabled ? "cursor-not-allowed" : "cursor-pointer")}
  ${(props) => (props.$disabled ? "text-gray-400" : "text-white")}
`;

// benefits
const BenefitListItemIconInner = styled.img`
  height: 125px;
  width: 125px;
  margin-right: 10px;
`;

export const BenefitListItemIcon = tw(BenefitListItemIconInner)`
  xs:w-full sm:w-full md:w-1/6 lg:w-1/6
`;

const BenefitListItemInfoInner = styled.div`
  margin-right: 10px;
`;

export const BenefitListItemInfo = tw(BenefitListItemInfoInner)`
  w-full sm:w-full md:w-5/6 lg:w-5/6
  flex
  flex-col
  xs:items-center sm:items-center md:items-start lg:items-start
`;

// authority
export const AuthorityArticle = tw(PatternBG)`
  w-full
  flex
  items-center
  justify-center
  bg-white
  p-10
  overflow-x-hidden
`;
